import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'favorite_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="favorite_28"><path d="M17.97 9.593l5.258.505c1.83.175 2.39 1.962.978 3.136l-4.116 3.42 1.528 5.573c.502 1.83-1.055 2.938-2.614 1.837l-5.002-3.533-5 3.533c-1.554 1.097-3.117-.006-2.615-1.837l1.528-5.572-4.116-3.421c-1.419-1.179-.86-2.96.977-3.137l5.257-.504 2.316-5.34c.725-1.671 2.582-1.67 3.307 0z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28FavoriteProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28Favorite: FC<Icon28FavoriteProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28Favorite as any).mountIcon = mountIcon;

export default Icon28Favorite;
