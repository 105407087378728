import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'favorite_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="favorite_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M14 19.178l5.69 3.476a.205.205 0 00.306-.223l-1.547-6.485 5.064-4.338a.205.205 0 00-.117-.36l-6.646-.533-2.56-6.156a.205.205 0 00-.38 0l-2.56 6.156-6.646.533a.205.205 0 00-.117.36l5.064 4.338-1.547 6.485a.205.205 0 00.306.223L14 19.178zm0 2.344L9.352 24.36a2.205 2.205 0 01-3.294-2.393l1.264-5.297-4.136-3.543a2.205 2.205 0 011.258-3.873l5.429-.435 2.091-5.028a2.205 2.205 0 014.072 0l2.091 5.028 5.429.435a2.205 2.205 0 011.258 3.873l-4.136 3.543 1.264 5.297a2.205 2.205 0 01-3.294 2.393L14 21.522z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28FavoriteOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28FavoriteOutline: FC<Icon28FavoriteOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28FavoriteOutline as any).mountIcon = mountIcon;

export default Icon28FavoriteOutline;
