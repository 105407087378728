import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'delete_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="delete_outline_28"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.944 9.502a1 1 0 00-.943 1.053l.5 9a1 1 0 001.998-.11l-.5-9a1 1 0 00-1.055-.943zm6.111 0A1 1 0 0118 10.555l-.5 9a1 1 0 01-1.997-.11l.5-9a1 1 0 011.053-.943z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 5a1 1 0 000 2h1.096l1.298 12.823c.073.73.135 1.334.224 1.826.092.513.227.98.488 1.414a4 4 0 001.72 1.554c.458.216.936.303 1.456.344.498.039 1.106.039 1.84.039h4.757c.733 0 1.34 0 1.84-.039.519-.04.997-.128 1.455-.344a4 4 0 001.72-1.554c.261-.434.396-.901.489-1.414.088-.492.15-1.097.223-1.826L23.404 7H24.5a1 1 0 100-2h-6.111a4.502 4.502 0 00-8.777 0H3.5zm4.38 14.583c.078.778.132 1.304.206 1.71.07.394.148.596.234.738a2 2 0 00.86.778c.15.07.359.127.758.158.41.032.94.033 1.722.033h4.68c.782 0 1.311 0 1.723-.033.399-.031.607-.087.758-.158.356-.169.656-.44.86-.778.085-.142.162-.344.233-.738.074-.406.128-.932.206-1.71L21.395 7H6.606L7.88 19.583zM14 3.5A2.5 2.5 0 0011.708 5h4.584A2.5 2.5 0 0014 3.5z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28DeleteOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28DeleteOutline: FC<Icon28DeleteOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28DeleteOutline as any).mountIcon = mountIcon;

export default Icon28DeleteOutline;
